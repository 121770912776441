.main-container, .footer-container{
  max-width: 1200px;
  margin: 0 auto;
}

.main-container{
  padding-bottom: 130px;
}

.footer-container{
  padding:10px;
}

.container{
  margin-bottom:100px;
}

.jumbotron{
  width:630px;
  margin:0 auto;
}

.footer{
  position: fixed;
    bottom: 0;
    width: 100%;
    background: #FFFFFF;
}

.logos{
  width:506px;
  margin: 0 auto;
}

.wtext{
  color:#FFFFFF;
}

.side-menu ul {
  text-decoration: none;
  list-style: none;
  padding: 0 !important;
  margin-top: 0;
  text-align: center;
}
  
a:hover{
  text-decoration: none !important;
}

h1, h2, h3, h4{
  margin: 0;
}

h1{
  color:#FFFFFF !important;
  padding-bottom:5px !important;
  border-bottom:1px solid #d2d3d3 ;
}

h2, h3{
  color:#FFFFFF;
}

.bronze{
  background: #CD7F32 !important;
}

.silver{
  background: rgb(138, 138, 138) !important;
}

.gold{
  background: #D4AF37 !important;
}

.platinum{
  background: #5c5c5b !important;
}

.diamond{
  background: #385f68 !important;
}

.side-menu{
  flex:1;
}

.application-form{
  flex:4;
}

.bp3-card{
  border-radius:18px !important;
}

.pt-card{
  margin-bottom: 18px;
}

.align-right {
  float: right;
}

.align-center {
  text-align: center;
}

input {
  width: 100%;
}
.disabledLink{
  cursor: default;
  background: #dddddd !important
}

.is-active{
  background: #333f48 !important;
  color:#FFF !important;
}

.gordAwardLevelStyle{
  display: block;
  flex-wrap: wrap;
  justify-content: center;
}

.bp3-card{
  margin-top:10px;
}

h5{ 
  color:#FFFFFF !important;
  display: inline;
  font-size:40px;
  margin:0 0 10px 0;
}

.loginStyles {
  width: 315px;
  max-width: 315px;
  margin: 20px auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
}

body {
  background:#000000;
}

@media(min-width:376px){
  .form{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .gordAwardLevelStyle{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .gordLevelButton {
    width: 20%;
    flex-Grow: 1;
    margin: 5px;
    text-align: center;
}

  .side-menu{
    margin-right:10px;
  }
}